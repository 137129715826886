export const getLinkText = ({ text, postCount, ariaText, showPostCount }) => {
  if (showPostCount) {
    return {
      text: `${text} ${postCount}`,
      ariaLabel: `${text} ${ariaText}`,
    };
  }

  return { text, ariaLabel: null };
};
