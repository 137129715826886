import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getFeedAndCategoryLinks, getCategoryLinks } from '../../../common/selectors/category-links-selectors';
import withTranslate from '../../../common/hoc/with-translate';
import LinkList from '../../../link-list';
import { getShowAllPostsCategory, getShowPostCount } from '../../selectors/category-menu-selectors';
import { getLinkText } from '../../../common/services/get-link-text';
import { getLocale } from '../../../common/selectors/locale-selectors';

const CATEGORY_LIMIT = 50;

const CategoryMenu = ({ t, categories, showPostCount }) => (
  <nav aria-label={t('aria-label.category-menu')}>
    <LinkList
      links={categories.slice(0, CATEGORY_LIMIT).map((category) => {
        const { text, ariaLabel } = getLinkText({
          showPostCount,
          text: category.text,
          postCount: t('category-link.post-count', { count: category.postAmount }),
          ariaText:
            category.postAmount === 1
              ? t('category-link.a11y-post-count', { count: category.postAmount })
              : t('category-link.a11y-post-count-plural', { count: category.postAmount }),
        });

        return {
          key: category.key,
          path: category.path,
          text,
          ariaLabel,
        };
      })}
      emptyState={{ text: t('empty-state.no-categories') }}
    />
  </nav>
);

CategoryMenu.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => {
  const showAllPostsCategory = getShowAllPostsCategory(state);
  const categories = showAllPostsCategory ? getFeedAndCategoryLinks(state, getLocale(state)) : getCategoryLinks(state);

  return {
    categories,
    showPostCount: getShowPostCount(state),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(CategoryMenu);
